// © Microsoft Corporation. All rights reserved.

import { startWidgetInitializer as originalStartWidgetInitializer } from "@msnews/widget-initializer";
import { initSapphire, promiseWithTimeout, setDefaultAppId, homepageAppId, loadDataAsync, saveDataAsync } from "@msnews/sapphire-feed-bridge";
import { isEdgeMobile, isLegacyEdgeMobile } from "@msnews/core";
import { WidgetInitializerOptions } from "@msnews/core-contracts";

declare global {
    interface Window {
        /**
         * Native bridge object to call native functions. It will be used by WidgetInitializer to save and load PCS cache from native storage
         */
        __bridgeSdk?: any;
    }
}

const startWidgetInitializer = function (options?: string | WidgetInitializerOptions) {
    if (isEdgeMobile() && !isLegacyEdgeMobile()) {
        initSapphire();
        setDefaultAppId(homepageAppId);
    }
    // for WidgetInitializer => getPcsResponse to save and load PCS cache from native storage
    window.__bridgeSdk = {
        promiseWithTimeout,
        loadDataAsync,
        saveDataAsync
    };
    originalStartWidgetInitializer(options);
};

export { startWidgetInitializer };
